import './App.css?v2.0.0';
import Home from "pages/Home"
import AdminLogin from "pages/AdminLogin"
import NotFound from "pages/NotFound"
import AdminDashboard from "pages/AdminDashboard"
import { useState, useEffect } from "react"
import Property from "pages/Property"
import Contact from "pages/Contact"
import Properties from "pages/Properties"
import AboutUs from "pages/AboutUs"
import Services from "pages/Services"

// import ProductList from "components/Products/ProductList"
import EditProperty from "components/EditProperty"
import Listing from "components/Listing"
import { Route, Routes } from "react-router-dom"
import PrivateRoutes from "components/ProtectedRoutes"
import PrivateRoutesConsultant from "components/ProtectedConsultant"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Messages from 'components/Messages';
import Message from 'components/Message';
import { useLocation } from "react-router-dom"
import Portal from 'pages/Portal';
import MainConsult from 'pages/Consultant/MainConsult';
import ConsultLogin from 'pages/Consultant/ConsultLogin';
import MajorConsult from 'pages/Consultant/MajorConsult';
import SettingConsultant from 'pages/Consultant/SettingConsultant';
import Password from 'components/Password';
import DataTable from "components/Consultants"
import InviteSuccess from 'components/InviteSuccess';
import NewProperty from 'pages/NewProperty';
import AdminPassword from 'pages/AdminPassword';
import SideNav from 'components/SideNav';
import AuthContext from "Context/AuthProvider"
import { useContext } from "react"
import ConsultPayment from "pages/Consultant/ConsultPayment";
import ConsultantDownline from 'pages/Consultant/ConsultantDownline';
import WidthdrawalList from 'pages/WithdrawalList';

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { nactive } = useContext(AuthContext)
  // useEffect(() => {
  //   setLoading(true)
  //   setTimeout(() => setLoading(false), 4000)
  // }, [])
  const [backToTopButton, setBackToTopButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTopButton(true)
      }
      else {
        setBackToTopButton(false)
      }
    })
  }, [])

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behaviour: "smooth"
    })
  }
  return (
    <>
      {
        nactive && <SideNav />
      }

      {/* {
        loading ?
          <div className='loader_section'>
            <CircleLoader color={"#F37A24"} loading={loading} size={150} />
          </div>

          :
          


      } */}
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/consultant" element={<ConsultLogin />} />
        <Route path="/portal/registration/email_sent" element={<InviteSuccess />} />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<AdminDashboard />} >
            <Route path="" element={<Listing />} />
            {/* <Route path="setting" element={<Setting />} /> */}
            <Route path="password" element={<AdminPassword />} />
            <Route path="properties" element={<Listing />} />
            <Route path="messages" element={<Messages />} />
            <Route path="consultants" element={<DataTable />} />
            <Route path="widthdrawal_list" element={<WidthdrawalList />} />
            <Route path="message/:id" element={<Message />} />
            <Route path="editproperty/:id" element={<EditProperty />} />
            <Route path="add_property" element={<NewProperty />} />
          </Route>
        </Route>
        <Route element={<PrivateRoutesConsultant />}>
          <Route path="/dashboardConsult" element={<MainConsult />}>
            <Route path="" element={<MajorConsult />} />
            <Route path="setting" element={< SettingConsultant />} />
            <Route path="update_password" element={< Password />} />
            <Route path="downline" element={< ConsultantDownline />} />
            <Route path="widthdrawal" element={< ConsultPayment />} />
          </Route>
        </Route>


        <Route path="/property/:id" element={<Property />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/properties" element={<Properties />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/portal/*" element={<Portal />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      {
        location?.pathname === "/dashboard" && <button onClick={scrollUp} className={backToTopButton ? "button activate " : "button"} >
          <KeyboardArrowUpIcon className="icon" />
        </button>
      }

      {
        location?.pathname === "/dashboard" && <a href="https://wa.me/+2348149229891" target="_blank" rel="noreferrer" className="whatsapp">
          <img src="/image/whatsapp.png" alt="" />
        </a>
      }

    </>
  );
}

export default App;
